body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(18,18,18);
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logohover:hover {
  transition: 2s ease;
  transform: scale(1.1);
}

.logohover {
  transition: 1s ease;
  animation: logoLoad 1.5s ease-out;
}

.bar {
  animation: logoLoad 2s ease-out;
}

.download {
  animation: downLoad 1cns ease-out;
}

.downloadButton {
  animation: downLoadButton 1s ease-out;
}

.nav {
  animation: navLoad 2s ease-out;
}

.navB {
  animation: logoLoad 2s ease;
}

.popin {
  animation: textLoad 1.5s;
}

.navitem {
  transition: 1s ease;
  text-decoration: none;
  color: white;
}

.navitem:hover {
  transition: 1s ease;
  transform: scaleX(1.1);
}

.menu {
  animation: menuLoad 1s ease;
}

#garageDoor {
  transition: 1s ease;
  animation: garageDoor 5s ease;
}

.slide-container {
  width: 100%;
  margin: auto; 
}

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 800px;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 75%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #000000;
}

@keyframes downLoad {
  from {
    opacity: 0;
    transform: translateY(-10px)
  } to {
    opacity: 1;
  }
}

@keyframes downLoadButton {
  0% {
    opacity: 0;
  } 50% {
    opacity: .3;
  }
}

@keyframes logoLoad {
  from {
    opacity: 0;
    transform: scale(.9);
  } to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes garageDoor {
  from {
    opacity: 1;
    transform: translateY(0px)
  } to {
    opacity: 1;
    transform: translateY(-650px)
    
  }
}

@keyframes navLoad {
  from {
    opacity: 0;
    transform: translateY(-50px);
  } to {
    opacity: 1;
  }
}

@keyframes textLoad {
  from {
    opacity: 0;
    transform: translateX(-100px)
  } to {
    opacity: 1;
  }
}

@keyframes menuLoad {
  from {
    height: 0px
  }
}